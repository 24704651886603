const steps = [
  {
    element: "#add-integration-button",
    popover: {
      title: "Add Integration",
      description: "Allows you to add a new integration",
      position: "bottom",
    },
  },
  {
    element: "#search-integration",
    popover: {
      title: "Search for Integreation",
      description: "Allows you to search for a specific integreation(s)",
      position: "bottom",
    },
  },
  {
    element: "#edit-integration-button",
    popover: {
      title: "Edit Integration",
      description: "Allows you to edit a integration",
      position: "bottom",
    },
  },
  {
    element: "#delete-integration-button",
    popover: {
      title: "Delete a Integration",
      description: "Allows you to delete a integration",
      position: "bottom",
    },
  },
  {
    element: "#pagination-integration",
    popover: {
      title: "Pagination",
      description: "Allows you to navigate through the integrations",
      position: "bottom",
    },
  },
];

export default steps;
