<template>
    <v-card>
        <v-card-text>
            <v-col>
                <v-row justify="end" class="mb-2">
                    <div style="margin-top: 20px;">
                        <v-chip class="mx-1" small
                            :style="selected === 'expired' ? { 'border': 'solid 0.5px', 'background-color': 'primary', 'color': 'white' } : { 'border': 'solid 0.5px', 'background-color': 'transparent', 'color': 'red' }"
                            @click="getDangerItems('expired')" :color="selected === 'expired' ? 'primary' : undefined"
                            v-if="expiredTotal > 0">
                            Expired : {{ expiredTotal }}
                        </v-chip>
                        <v-chip class="mx-1" small
                            :style="selected === 'upcoming' ? { 'border': 'solid 0.5px', 'background-color': 'transparent', 'color': 'white' } : { 'border': 'solid 0.5px', 'background-color': 'transparent', 'color': 'orange' }"
                            @click="getDangerItems('upcoming')" :color="selected === 'upcoming' ? 'orange' : undefined"
                            v-if="upcomingTotal > 0">
                            Upcoming : {{ upcomingTotal }}
                        </v-chip>
                    </div>
                    <v-col cols="12" sm="12" md="8" lg="6">

                        <el-input id="search-user" suffix-icon="el-icon-search" v-model="search" clearable
                            placeholder="Search">
                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table :headers="headers" :items="contractData" :items-per-page="15" class="elevation-1"
                    :loading="loadingData">
                    <template v-slot:[`item.action`]={item}>
                        <v-btn icon @click="editContract(item)" color="primary">
                            <v-icon small>edit</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.party`]={item}>
                        {{ item.contractParty.name }} {{ item.contractParty.alias ? '(' + item.contractParty.alias + ')' : '' }}
                    </template>

                </v-data-table>

            </v-col>

        </v-card-text>

        <v-dialog v-model="editModal" max-width="1200px" persistent v-if="editModal">
            <v-card>
                <v-card-title class="headline d-flex justify-center">Contract Details
                    <v-spacer></v-spacer>
                    <v-btn icon @click="editModal = false" color="green">
                        <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn icon @click="editModal = false" color="primary">
                        <v-icon>delete</v-icon>
                    </v-btn>
                    <v-btn icon @click="editModal = false" color="secondary">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-card-text>
                                <v-text-field label="Contract No"
                                    v-model="currentContract.contracts[0].contractNo"></v-text-field>

                                <v-select :items="['SHIPPING_LINE', 'COLDSTORE', 'WEIGHBRIDGE']" label="Type"
                                    v-model="currentContract.type"></v-select>
                                <v-select :items=contractOwners label="Contact Owner"
                                    v-model="currentContract.contractOwner.name"
                                    item-text="relatedOrganisation.name"></v-select>
                                <v-select :items=contractParties label="Contract Party"
                                    v-model="currentContract.contractParty.name" item-text="name"></v-select>


                                <v-text-field label="Start Date" v-model="currentContract.contracts[0].startDate"
                                    @click="dateModal = true"></v-text-field>
                                <v-text-field label="End Date" v-model="currentContract.contracts[0].endDate"
                                    @click="dateModal = true"></v-text-field>


                            </v-card-text>
                        </v-col>
                        <v-col cols="6">
                            <v-card-text>
                                <v-select multiple chips :items="contractProducts" label="Products"
                                    v-model="currentContract.contractProduct" item-value="id" item-text="product.name"
                                    clearable>
                                    <template #selection="{ item }">
                                        <v-chip small>{{ item.product.name }}</v-chip>
                                    </template>
                                </v-select>
                                <v-select :items=contractOwners label="Location"
                                    v-model="currentContract.contractLocations.name" item-text="name"></v-select>
                                <v-select :items=contractOwners label="Item" v-model="currentContract.contractItems.name"
                                    item-text="name"></v-select>

                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
export default {
    props: ['organisation'],
    data() {
        return {
            contractOwners: [],
            contractParties: [],
            loadingData: false,
            expiredData: [],
            upcomingData: [],
            expiredTotal: 0,
            upcomingTotal: 0,
            search: '',
            selected: '',
            headers: [
                {
                    text: '',
                    value: 'action',
                    sortable: false
                },
                {
                    text: 'Contract No',
                    value: 'contracts[0].contractNo'
                },
                {
                    text: 'Conract Owner',
                    value: 'contractOwner.name'
                },
                {
                    text: 'Contract Party',
                    value: 'party'
                },
                {
                    text: 'Start Date',
                    value: 'contracts[0].startDate'
                },
                {
                    text: 'End Date',
                    value: 'contracts[0].endDate'
                }
            ],
            contractData: [],
            editModal: false,
            currentContract: {},
            contractProducts:[]
        }
    },
    watch: {
        'currentContract.type': function (val) {
            this.getPartyList(val)
        }
    },
    methods: {
        async getOrganisationContractData() {
            this.loadingData = true
            let result = await this.$API.getOrganisationContracts()
            this.contractData = result.data
            this.loadingData = false
        },
        async editContract(item) {
            this.currentContract = item
            console.log('currentContract', this.currentContract);
            await this.getPartyList(this.currentContract.type)
            await this.getCustomerList()
            await this.getProducts()
            this.editModal = true
        },
        async getDangeredContracts() {
            let res = await this.$API.getDangerContractCount()
            this.expiredTotal = res.expiredCount
            this.upcomingTotal = res.upcomingCount
        },
        async getDangerItems(type) {
            this.loadingData = true
            if (this.selected != type) {
                this.selected = type
                this.contractData = await this.$API.getDangerContractItems(type)
            }
            else {
                this.selected = null
                await this.getOrganisationContractData()
            }
            this.loadingData = false
        },
        async getCustomerList() {
            let result = await this.$API.listCustomerOptions()
            this.contractOwners = result
            console.log('contractowners', this.contractOwners);
        },
        async getPartyList(type) {
            let result = await this.$API.listPartyOptions(type)
            this.contractParties = result
            // console.log(this.contractParties);
        },
        async getProducts() {
            this.contractProducts=[{id:161,name:'ORANGES'}]
        }
    },
    async mounted() {
        await this.getOrganisationContractData()
        await this.getDangeredContracts()
    }
}
</script>
<style>
.custom-prepend-class .v-chip {
    background: none;
    border: none;
}</style>
