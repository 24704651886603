const steps = [
  {
    element: "#int-type",
    popover: {
      title: "Integration - Type",
      description: "This is where you provide the type of this integration",
      position: "left",
    },
  },
  {
    element: "#int-name",
    popover: {
      title: "Integration - Alias",
      description: "This is where you provide the alias of this integration",
      position: "left",
    },
  },
  {
    element: "#int-path",
    popover: {
      title: "Integration - Path",
      description: "This is where you provide the path of this integration",
      position: "top",
    },
  },
  {
    element: "#int-IP",
    popover: {
      title: "Integration - Host/IP",
      description: "This is where you provide the Host/IP of this integration",
      position: "top",
    },
  },
  {
    element: "#int-user",
    popover: {
      title: "Integration - User Details",
      description:
        "This is where you provide the users' details of this integration",
      position: "left",
    },
  },
  {
    element: "#int-check",
    popover: {
      title: "Integration - FTP Check",
      description:
        "Extremely important check to answer as it can heavily affect this integration",
      position: "right",
    },
  },
];

export default steps;
