var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loaded)?_c('div',[_c('v-card',{staticStyle:{"background-color":"var(--v-background-base) !important"},attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"greyBase","dark":"","extended":!_vm.$vuetify.breakpoint.mobile,"flat":"","extension-height":"55"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-toolbar-title',[_c('b',[_vm._v(_vm._s(_vm.item.name))])])],1),_c('v-card',{staticClass:"mx-auto",style:({
          'margin-top': !_vm.$vuetify.breakpoint.mobile ? '-64px' : '0px',
          'border-radius': !_vm.$vuetify.breakpoint.mobile ? '50px' : '0px',
        }),attrs:{"max-width":!_vm.$vuetify.breakpoint.mobile ? '94vw' : '100%'}},[_c('v-card-text',{style:({
              height: !_vm.$vuetify.breakpoint.mobile ? '90vh' : '90vh',
              'overflow-y': 'auto',
            })},[_c('v-tabs',{attrs:{"height":"35px","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#info"}},[_vm._v(" Info ")]),_c('v-tab',{attrs:{"href":"#users"}},[_vm._v(" User Management ")]),_c('v-tab',{attrs:{"href":"#integration"}},[_vm._v(" Integrations ")]),_c('v-tab',{attrs:{"href":"#service-failure"}},[_vm._v(" Service Failures ")])],1),_c('v-tabs-items',{staticStyle:{"max-height":"80vh","overflow-y":"auto"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"info"}},[_c('Info',{attrs:{"item":_vm.item}})],1),_c('v-tab-item',{attrs:{"value":"users"}},[_c('User')],1),_c('v-tab-item',{attrs:{"value":"documents"}},[_c('Documents')],1),_c('v-tab-item',{attrs:{"value":"roles"}},[_c('Role')],1),_c('v-tab-item',{attrs:{"value":"teams"}},[_c('Team')],1),_c('v-tab-item',{attrs:{"value":"carrier-contract"}},[_c('CarrierContract')],1),_c('v-tab-item',{attrs:{"value":"integration"}},[_c('Integration',{attrs:{"organisation":_vm.item}})],1),_c('v-tab-item',{attrs:{"value":"service-failure"}},[_c('ServiceFailureCategory')],1),_c('v-tab-item',{attrs:{"value":"contracts"}},[_c('Contract',{attrs:{"organisation":_vm.item}})],1)],1)],1)],1)],1)],1):_c('div',{staticClass:"text-center",staticStyle:{"height":"95vh"}},[_c('valhalla-loading')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }