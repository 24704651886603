<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row justify="end" class="mb-2">
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <el-input id="search-sf" suffix-icon="el-icon-search" v-model="search" clearable
                            placeholder="Search">
                            <template slot="prepend">
                                <el-button id="add-sf-button" @click="addCategory()"><i
                                        class="el-icon-plus"></i></el-button>
                            </template>
                            <template slot="append">
                                <el-button @click="infoStartGuide">
                                    <v-icon>help</v-icon>
                                </el-button>
                            </template>
                            <!-- <template slot="append">
                                <el-button @click="startGuide">
                                    <v-icon>help</v-icon>
                                </el-button>
                            </template> -->
                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table dense :items="categories" :loading="loadingCategory" :headers="headers" :search="search"
                    disable-pagination hide-default-footer>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn id="edit-sf-button" small class="mx-1" icon @click="editItem(item)">
                            <v-icon small>edit</v-icon>
                        </v-btn>
                        <v-btn id="remove-sf-button" small class="mx-1" icon @click="deleteCategory(item)">
                            <v-icon small color="red">delete</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.subcategories`]="{ item }">
                        <v-chip>{{ item.serviceFailureSubcategories.length }}</v-chip>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="modal" persistent width="1200px">
            <v-card>
                <v-card-title>
                    Manage Category
                    <v-spacer></v-spacer>
                    <v-btn id="info-guide" @click="dialogStartGuide" fab height="4vh" width="4vh">
                        <v-icon>help</v-icon>
                    </v-btn>
                    <v-btn id="category-save" icon color="primary" :loading="savingCategory"
                        @click="saveCategory"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="modal = false, item = {}">X</v-btn>
                </v-card-title>
                <v-card-text v-if="modal">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field id="category-name" label="Name" v-model="item.name" outlined dense
                                clearable></v-text-field>
                            <v-textarea id="category-description" label="Description" v-model="item.description" clearable
                                outlined rows="6"></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6" id="category-sub">
                            <v-list dense subheader>
                                <v-subheader style="background: var(--v-greyBase-base); font-size: 16px">Subcategories
                                    <v-btn color="primary" @click="addSubCategory()"
                                        icon><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                                <v-list-item v-for="(subcategory, index) in item.serviceFailureSubcategories"
                                    :key="subcategory.id" @click="editSubCategory(subcategory, index)">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ subcategory.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px" v-if="subcategory.description"
                                            class="text-wrap">
                                            {{ subcategory.description }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.serviceFailureSubcategories.length == 0">
                                    <v-list-item-content class="text-center">
                                        <span style="color: grey">No subcategories</span>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="subcategoryModal" persistent width="400px">
            <v-card>
                <v-card-title>
                    Manage Subcategory <v-spacer></v-spacer>
                    <!-- <v-btn id="info-guide" @click="subStartGuide" fab height="4vh" width="4vh">
                        <v-icon>help</v-icon>
                    </v-btn> -->
                    <v-btn text @click="subcategoryModal = false, subcategoryItem = {}">X</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field id="sub-name" label="Name" v-model="subcategoryItem.name" outlined dense
                        clearable></v-text-field>
                    <v-textarea id="sub-description" label="Description" v-model="subcategoryItem.description" clearable
                        outlined rows="6"></v-textarea>
                    <v-row justify="center">
                        <v-col cols="12" class="text-center">
                            <v-btn id="sub-delete" icon color="red" :loading="deletingSubcategory"
                                @click="deleteSubcategory(subcategoryItem)"><v-icon>delete</v-icon></v-btn>
                            <v-btn id="sub-save" icon color="blue" :loading="savingSubcategory"
                                @click="saveSubcategory"><v-icon>save</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="infoStart" max-width="31vw">
            <v-card>
                <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
                        color="primary" size="30">close</v-icon></v-btn>
                <v-card-title class="d-flex justify-center">
                    FRIKO Welcome to Service Failures!
                </v-card-title>
                <v-card-text>
                    <span>
                        <p>FRIKO</p>
                        <p>FRIKO
                            <br>• Map out routes for transporters
                            <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
                            <br>• Calculate accurate waiting times at loading points
                            <br>• Calculate distances for billing purposes
                            <br>• Pin-point locations for the purpose of reporting and analysis
                            <br>• Map out farms that aren't found on Google Maps
                        </p>
                        If you would like learn how to load POI's into the system, click the button below to start the
                        tutorial.
                    </span>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogStart" max-width="31vw">
            <v-card>
                <v-btn @click="dialogStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
                        color="primary" size="30">close</v-icon></v-btn>
                <v-card-title class="d-flex justify-center">
                    FRIKO Welcome to Edit/Add Service Failures!
                </v-card-title>
                <v-card-text>
                    <span>
                        <p>FRIKO</p>
                        <p>FRIKO
                            <br>• Map out routes for transporters
                            <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
                            <br>• Calculate accurate waiting times at loading points
                            <br>• Calculate distances for billing purposes
                            <br>• Pin-point locations for the purpose of reporting and analysis
                            <br>• Map out farms that aren't found on Google Maps
                        </p>
                        If you would like learn how to load POI's into the system, click the button below to start the
                        tutorial.
                    </span>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn @click="startDialogGuide" color="edit" class="mb-3">Start!</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="subStart" max-width="31vw">
            <v-card>
                <v-btn @click="subStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
                        color="primary" size="30">close</v-icon></v-btn>
                <v-card-title class="d-flex justify-center">
                    FRIKO Welcome to Edit/Add Subcategories!
                </v-card-title>
                <v-card-text>
                    <span>
                        <p>FRIKO</p>
                        <p>FRIKO
                            <br>• Map out routes for transporters
                            <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
                            <br>• Calculate accurate waiting times at loading points
                            <br>• Calculate distances for billing purposes
                            <br>• Pin-point locations for the purpose of reporting and analysis
                            <br>• Map out farms that aren't found on Google Maps
                        </p>
                        If you would like learn how to load POI's into the system, click the button below to start the
                        tutorial.
                    </span>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn @click="startSubGuide" color="edit" class="mb-3">Start!</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import sfSteps from './Steps/serviceFailureSteps'
import dialogSteps from './Steps/sfDialogSteps'
import subSteps from './Steps/sfSubDialogSteps'

export default {
    data: () => ({
        categories: [],
        deletingCategory: false,
        deletingSubcategory: false,
        headers: [
            {
                text: 'Action',
                align: 'center',
                sortable: false,
                value: 'action'
            },
            {
                text: 'Name',
                value: 'name',
                align: 'left',
            },
            {
                text: 'Description',
                value: 'description',
                align: 'left',
            },
            {
                text: 'Subcategories',
                value: 'subcategories',
                align: 'center',
            }
        ],
        loadingCategory: false,
        modal: false,
        savingCategory: false,
        savingSubcategory: false,
        search: null,
        subcategoryItem: {},
        subcategoryModal: false,
        infoStart: false,
        dialogStart: false,
        dialogSub: false
    }),
    created() {
        this.getCategories()
    },
    async mounted() {
        this.driver = new Driver({
            animate: false
        })
    },
    methods: {
        addCategory() {
            this.item = {
                serviceFailureSubcategories: []
            }
            this.modal = true
        },
        addSubCategory() {
            this.subcategoryItem = {
                serviceFailureCategoryId: this.item.id ?? null,
                index: this.item.serviceFailureSubcategories.length
            }
            this.subcategoryModal = true
        },
        deleteItem(item) {
            console.log(item)
        },
        deleteCategory(category) {
            this.$confirm('Are you sure you want to delete this category?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.deletingCategory = true
                if (category.id) {
                    await this.$API.updateServiceFailureCategory({
                        id: category.id,
                        isActive: false,
                        isDeleted: true
                    })
                }
                let findIndex = this.categories.findIndex(x => x.id == category.id)
                this.categories.splice(findIndex, 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
                this.deletingCategory = false

            }).catch(() => {
                this.deletingCategory = false
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        deleteSubcategory(subcategory) {
            this.$confirm('Are you sure you want to delete this subcategory?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.deletingSubcategory = true
                if (subcategory.id) {
                    await this.$API.updateServiceFailureSubcategory({
                        id: subcategory.id,
                        isActive: false,
                        isDeleted: true
                    })
                }
                // let findIndex = this.item.serviceFailureSubcategories[subcategory.index]
                // if(findIndex){
                console.log("SPlicing index", subcategory.index)
                this.item.serviceFailureSubcategories.splice(subcategory.index, 1)
                // }
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
                this.subcategoryModal = false
                this.subcategoryItem = {}
                this.deletingSubcategory = false

            }).catch(() => {
                this.deletingSubcategory = false
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        editItem(item) {
            this.modal = true
            this.item = item
        },
        editSubCategory(item, index) {
            this.subcategoryItem = item
            this.subcategoryItem.index = index
            console.log(index)
            this.subcategoryModal = true
        },
        async getCategories() {
            this.loadingCategory = true
            this.categories = await this.$API.getServiceFailureCategories()
            this.loadingCategory = false
        },
        async saveCategory() {
            this.savingCategory = true
            if (this.item.id) {
                await this.$API.updateServiceFailureCategory(this.item)
                this.$message.success('Successfully updated category!')
                let findIndex = this.categories.findIndex(x => x.id == this.item.id)
                this.categories[findIndex] = this.item
            } else {
                await this.$API.createServiceFailureCategory(this.item)
                this.getCategories()
                this.$message.success('Successfully created category!')
            }
            this.modal = false
            this.item = {}
            this.savingCategory = false
        },
        async saveSubcategory() {
            this.savingSubcategory = true
            if (this.subcategoryItem.id) {
                await this.$API.updateServiceFailureSubcategory(this.subcategoryItem)
                this.$message.success('Successfully updated subcategory!')
                let findIndex = this.item.serviceFailureSubcategories.findIndex(x => x.id == this.subcategoryItem.id)
                this.item.serviceFailureSubcategories[findIndex] = this.subcategoryItem
            } else if (this.subcategoryItem.serviceFailureCategoryId) {
                let result = await this.$API.createServiceFailureSubcategory(this.subcategoryItem)
                this.item.serviceFailureSubcategories.push(result)
                this.$message.success('Successfully created subcategory!')
            } else {
                let findIndex = this.item.serviceFailureSubcategories.findIndex(x => x.index == this.subcategoryItem.index)
                if (findIndex > -1) {
                    this.item.serviceFailureSubcategories[findIndex] = this.subcategoryItem
                } else {
                    this.item.serviceFailureSubcategories.push(this.subcategoryItem)
                }
            }
            this.subcategoryModal = false
            this.subcategoryItem = {}
            this.savingSubcategory = false
        },
        infoStartGuide() {
            if (this.infoStart) {
                this.infoStart = false;
            } else {
                this.infoStart = true;
            }
        },
        startGuide(e) {
            this.infoStart = false
            e.stopPropagation();
            this.driver.defineSteps(sfSteps)
            this.driver.start()
        },
        dialogStartGuide() {
            if (this.dialogStart) {
                this.dialogStart = false;
            } else {
                this.dialogStart = true;
            }
        },
        startDialogGuide(e) {
            this.dialogStart = false
            e.stopPropagation();
            this.driver.defineSteps(dialogSteps)
            this.driver.start()
        },
        subStartGuide() {
            if (this.subStart) {
                this.subStart = false;
            } else {
                this.subStart = true;
            }
        },
        startSubGuide(e) {
            this.subStart = false
            e.stopPropagation();
            this.driver.defineSteps(subSteps)
            this.driver.start()
        },
    }
}
</script>