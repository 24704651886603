const steps = [
  {
    element: "#sub-name",
    popover: {
      title: "Subcategory - Name",
      description:
        "This is where you provide the unique name of this subcategory",
      position: "left",
    },
  },
  {
    element: "#sub-description",
    popover: {
      title: "Subcategory - Description",
      description:
        "This is where you provide the description of this subcategory",
      position: "left",
    },
  },
  {
    element: "#sub-delete",
    popover: {
      title: "Subcategory - Removal",
      description: "Simply allows you to remove this subcategory",
      position: "bottom",
    },
  },
  {
    element: "#sub-save",
    popover: {
      title: "Subcategory - Save",
      description:
        "Saves the information and adds this subcategory to the manual category",
      position: "bottom",
    },
  },
];

export default steps;
