<template>
  <div>
      <div v-if="loaded">
        <v-card
          flat
          style="background-color: var(--v-background-base) !important"
        >
          <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
            <v-btn icon @click="$router.go(-1)">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>
              <b>{{item.name}}</b>
            </v-toolbar-title>
          </v-toolbar>
  
          <v-card
            class="mx-auto"
            :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
            :style="{
            'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
            'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
          }"
          >
            <v-card-text
              :style="{
                height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                'overflow-y': 'auto',
              }"
            >
  
      <v-tabs v-model="tab" height="35px" show-arrows>
          <v-tab href="#info">
              Info
          </v-tab>
          <v-tab href="#users">
              User Management
          </v-tab>
          <!-- <v-tab href="#roles">
              Roles
          </v-tab>
          <v-tab href="#teams">
              Teams
          </v-tab> -->
            <!-- <v-tab href="#carrier-contract">
              Carrier Contracts
          </v-tab> -->
          <v-tab href="#integration">
              Integrations
          </v-tab>
          <v-tab href="#service-failure">
              Service Failures
          </v-tab>
  
          <!-- <v-tab href="#documents">
            Documents
          </v-tab>  -->
  
          <!-- <v-tab href="#contracts">
              Contract Management
          </v-tab> -->
      </v-tabs>
      <v-tabs-items v-model="tab" style="max-height: 80vh; overflow-y: auto">
        <!-- <v-tab-item value="Overview">
              <Overview :item="item"/>
          </v-tab-item> -->
          <v-tab-item value="info">
              <Info :item="item"/>
          </v-tab-item>
          <v-tab-item value="users">
              <User />
          </v-tab-item>
  
  
  
          <!--New Documents Tab for markdown file   -->
          <v-tab-item value="documents">
              <Documents />
          </v-tab-item>
  
  
          <v-tab-item value="roles">
              <Role />
          </v-tab-item>
          <v-tab-item value="teams">
              <Team />
          </v-tab-item>
          <v-tab-item value="carrier-contract">
              <CarrierContract />
          </v-tab-item>
          <v-tab-item value="integration">
              <Integration :organisation="item" />
          </v-tab-item>
          <v-tab-item value="service-failure">
              <ServiceFailureCategory />
          </v-tab-item>
          <v-tab-item value="contracts">
              <Contract :organisation="item" />
          </v-tab-item>
      </v-tabs-items>
            </v-card-text>
            </v-card>
            </v-card>
      </div>
      <div
        v-else
        style="height: 95vh"
        class="text-center"
      >
      <!-- <v-card flat>
        <v-card-text> -->
          <valhalla-loading />
        <!-- </v-card-text>
      </v-card> -->
      </div>
  
  </div>
  </template>
  
  <script>
  import CarrierContract from '../components/Management/CarrierContract.vue'
  import Info from '../components/Management/Info.vue'
  import Integration from '../components/Management/Integration.vue'
  // import Overview from '../components/Management/Overview.vue'
  import Role from '../components/Management/Roles.vue'
  import ServiceFailureCategory from '../components/Management/ServiceFailureCategory.vue'
  import Team from '../components/Management/Team.vue'
  import User from '../components/Management/Users.vue'
  import Contract from '../components/Management/Contract.vue'
  //New Components for rendering the vue Markdown Component 
  import Documents from '../components/Management/Documents.vue'
  export default {
      components: {
          CarrierContract,
          Info,
          Integration,
          // Overview,
          Role,
          ServiceFailureCategory,
          Team,
          User,
          Contract,
          Documents,
          
      },
      data: () => ({
          item: {},
          loaded: false,
          tab: 0
      }),
      async created() {
          this.loaded = false
          this.item = await this.$API.getOrganisation()
          this.loaded = true
      }
  }
  </script>
  
  <style lang="scss">
  .active-tab {
      background-color: var(--v-background-base) !important;
      color: var(--v-primaryText-base) !important;
      font-size: 10px;
      border-top: 1px solid var(--v-primary-base);
      //  border-right: 1px solid lightgrey;
      //  border-left: 1px solid lightgrey;
  }
  </style>