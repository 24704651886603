<template>
<div style="background: var(--v-component-base) !important">
    <v-card flat>

        <!-- <v-card-title>
        <v-icon class="mr-2" color="secondary">code</v-icon> Available Integrations
      </v-card-title> -->
        <v-row align="center" justify="end">
            <v-col cols="12" sm="6" md="4">
                <el-input id="search-integration" ref="searchBar" suffix-icon="el-icon-search" v-model="search" clearable placeholder="Search">
                    <!-- <template slot="prepend">
            <el-button id="add-integration-button" @click="addIntegration"><i class="el-icon-plus"></i></el-button>
          </template> -->
                    <template slot="append">
                        <el-button @click="infoStartGuide">
                            <v-icon>help</v-icon>
                        </el-button>
                    </template>
                </el-input>
            </v-col>
        </v-row>
        <v-card-text style="height: 60; overflow-y: auto">
            <v-progress-linear v-if="loadingCountryIntegrations" color="primary" indeterminate></v-progress-linear>
            <v-row justify="start" v-if="!loadingCountryIntegrations">
                <v-col cols="12" class="text-center" v-if="!loadingCountryIntegrations && filterCountryIntegrations.length==0">
                    <span style="color: grey">No integrations available for your country</span>
                </v-col>
                <v-col cols="12" sm="3" v-for="(integration, index) in filterCountryIntegrations" :key="integration.id">
                    <v-hover v-slot="{ hover }">
                        <v-card color="background" style="border-radius: 20px" :elevation="hover ? 20 : 2" @click="manageIntegration(integration, index)">
                            <v-card-text>
                                <v-row justify="center" class="my-3">
                                    <v-avatar size="128px" color="white">
                                        <v-img contain :src="integration.icon"></v-img>
                                    </v-avatar>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <h4>
                                            <v-icon color="grey" class="mr-2" small>code</v-icon> {{ integration.name }}
                                        </h4>
                                        <h4 v-if="integration.governingBody">
                                            <v-icon color="grey" class="mr-2" small>business</v-icon> {{
                        integration.governingBody.name }}
                                        </h4>
                                        <h4 v-else>
                                            <v-icon color="grey" class="mr-2" small>business</v-icon> {{
                        integration.organisationName }}
                                        </h4>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-icon style="position: absolute; top: 10px; right: 10px" :key="integrationKey" v-if="integration.integrations && integration.integrations.length>0" color="success" large>check</v-icon>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

    <!-- <v-data-table class="mt-3" dense disable-pagination fixed-header hide-default-footer :loading="loading"
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="integrations.data" :headers="headers" height="65vh"
      :calculate-widths="true" style="cursor: pointer">
      <template v-slot:[`item.action`]="{ item }">
        <v-row justify="center">
          <v-btn icon small>
            <v-icon id="edit-integration-button" small @click="editIntegrations(item)">edit</v-icon>
          </v-btn>
          <v-btn icon small color="danger" @click="deleteIntegration(item)">
            <v-icon id="delete-integration-button" small>delete</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        <v-icon v-if="item.isActive" color="success">check</v-icon>
        <v-icon v-else color="danger">close</v-icon>
      </template>
    </v-data-table>
    <v-col cols="12" class="text-center">
      <el-pagination id="pagination-integration" style="color: var(--v-primaryText-base)" :current-page.sync="page"
        :pager-count="5" :page-size.sync="params.limit" :page-sizes="[18, 25, 50, 100]" :layout="!$vuetify.breakpoint.mobile
          ? 'sizes, prev, pager, next, jumper, total'
          : 'prev, pager, next'
          " :total="integrations.total">
      </el-pagination>
    </v-col> -->

    <!-- <v-dialog persistent v-model="dialog" max-width="800px">
      <v-card :loading="saving" dark>
        <v-toolbar dark>
          <v-toolbar-title>{{ modalType }} Integration</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn id="info-guide" @click="dialogStartGuide" fab height="4vh" width="4vh">
            <v-icon>help</v-icon>
          </v-btn>
          <v-btn id="int-save" plain depressed elevation="0" @click="saveIntegration"><v-icon>save</v-icon></v-btn>
          <v-btn plain depressed elevation="0" @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-select id="int-type" v-model="integration.type" :items="availableIntegrations" label="Type" outlined
              dense></v-select>
            <div v-if="integration.type === 'FTP' || integration.type === 'sFTP'">
              <v-row>
                <v-col>
                  <v-text-field id="int-name" outlined dense v-model="integration.name" label="Alias"
                    @input="onNameSelected"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field id="int-path" outlined dense v-model="integration.path" label="Path"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field outlined id="int-IP" dense v-model="integration.host_ip" label="Host/IP"></v-text-field>
                </v-col>
              </v-row>

              <v-row id="int-user">
                <v-col>
                  <v-text-field outlined dense v-if="integration.integrationAuths.length === 0"
                    v-model="integration.username" label="Username"></v-text-field>
                  <v-text-field outlined dense v-else v-model="integration.users[0].username"
                    label="Username"></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field outlined dense v-if="integration.users.length === 0" v-model="integration.password"
                    label="Password" type="password"></v-text-field>
                  <v-text-field outlined dense v-else v-model="integration.users[0].password" label="Password"
                    type="password"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox id="int-check" outlined dense v-model="integration.keep_file"
                    label="Keep file on server"></v-checkbox>
                </v-col>
              </v-row>
            </div>

            <div v-if="integration.type === 'Inttra' ||
              integration.type === 'Sensor' ||
              integration.type === 'COO'
              ">
              <v-row>
                <v-col>
                  <v-text-field id="int-name" outlined dense v-model="integration.name" label="Alias"
                    @input="onNameSelected"></v-text-field>
                </v-col>
              </v-row>
              <br />
              <div v-for="auth in integration.integrationAuths" :key="auth.id">
                <v-row id="int-user">
                  <v-col>
                    <v-text-field outlined dense v-model="auth.username" label="Username"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field outlined dense v-model="auth.password" label="Password" type="password"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn small icon @click="deleteUser(auth)">
                      <v-icon color="red">remove_circle_outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <br />
              <v-btn block @click="addAnotherUser" outlined dense>Add user</v-btn>
            </div>

            <div v-if="integration.type === 'DHL'">
              <v-row>
                <v-col>
                  <v-text-field id="int-name" outlined dense v-model="integration.name" label="Alias"
                    @input="onNameSelected"></v-text-field>
                </v-col>
              </v-row>
              <v-row id="int-user">
                <v-col>
                  <v-text-field outlined dense v-model="integration.client_id" label="Client ID"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field outlined dense v-model="integration.client_secret" label="Secret Key"></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div v-if="integration.type === 'API'">
              <v-row>
                <v-col>
                  <v-text-field id="int-name" outlined dense v-model="integration.name" label="Alias"
                    @input="onNameSelected"></v-text-field>
                </v-col>
              </v-row>

              <div v-for="auth in integration.integrationAuths" :key="auth.id">
                <v-row id="int-user">
                  <v-col>
                    <v-text-field outlined dense v-model="auth.username" label="Username"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field outlined dense v-model="auth.password" label="Password"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field outlined dense v-model="auth.token" label="Secret Key / Token"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn small icon @click="deleteUser(auth)">
                      <v-icon color="red">remove_circle_outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-container>
        </v-card-text>
      </v-card>

    </v-dialog> -->

    <v-dialog persistent v-model="integrationModal" width="1200px" :fullscreen="$vuetify.breakpoint.mobile">
        <v-card>
            <v-toolbar flat color="transparent">
                <v-toolbar-title>
                    Manage Integration
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="integrationModal = false, integration = {}">X</v-btn>
            </v-toolbar>
            <v-card-text class="mx-0">
                <v-row justify="center">
                    <v-col cols="12" sm="4" class="my-0 py-0">
                        <v-card flat>
                            <v-card-text>
                                <v-row justify="center" class="my-3">
                                    <v-avatar size="128px" color="white">
                                        <v-img contain :src="integration.icon"></v-img>
                                    </v-avatar>
                                </v-row>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-icon class="mr-2" small color="grey">code</v-icon> {{ integration.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="integration.governingBody">
                                                <v-icon color="grey" class="mr-2" small>business</v-icon> {{ integration.governingBody.name }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>
                                                <v-icon color="grey" class="mr-2" small>business</v-icon> {{ integration.organisationName }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <p style="color: grey; font-size: 12px" v-if="integration.description">{{ integration.description }}
                                    </p>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- <v-col cols="1" class="text-center">
                        <v-divider vertical></v-divider>
                    </v-col> -->
                    <v-col cols="12" sm="4" class="mx-0 px-0">
                        <v-card flat>
                            <v-card-text v-if="integration.type == 'Login'" class="mx-0 px-0">
                                <v-subheader style="font-size: 16px">
                                    <v-icon color="secondary" class="mr-2">lock</v-icon>Credentials <v-spacer></v-spacer>
                                    <v-btn color="primary" icon @click="addCredential(integration.type)">
                                        <v-icon>add_circle_outline</v-icon>
                                    </v-btn>
                                </v-subheader>
                                <!-- <v-divider></v-divider> -->
                                <v-list dense>
                                    <v-list-item v-for="(auth, index) in integration.integration.integrationAuths" :key="auth.id" @click="manageCredential(auth, integration.type, index)">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 12px">
                                                <v-icon color="grey" small class="mr-2">person</v-icon> {{ auth.username }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px;">
                                                <v-icon color="grey" small class="mr-2">lock</v-icon>
                                                <span v-if="!auth.unlock" class="mb-0 pb-0">**********</span>
                                                <span v-else>{{ auth.password }}</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="integration.integration.integrationAuths.length ==0">
                                        <v-list-item-content class="text-center">
                                            <span style="color: grey">No credentials</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                            <v-card-text v-else-if="integration.type == 'API' && integration.subtype != 'OAUTH'">
                                <v-subheader style="font-size: 16px">
                                    <v-icon color="secondary" class="mr-2">lock</v-icon> Credentials <v-spacer></v-spacer>
                                    <v-btn color="primary" icon @click="addCredential(integration.type, integration.subtype)">
                                        <v-icon>add_circle_outline</v-icon>
                                    </v-btn>
                                </v-subheader>
                                <v-divider></v-divider>
                                <v-list dense>
                                    <v-list-item v-for="(auth, index) in integration.integration.integrationAuths" :key="auth.id" @click="manageCredential(auth, integration.type, index, integration.subtype)">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 12px">
                                                Client ID: {{ auth.username }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px;">
                                                Secret Key: {{ auth.password }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="integration.integration.integrationAuths.length ==0">
                                        <v-list-item-content class="text-center">
                                            <span style="color: grey">No credentials</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                            <v-card-text v-else-if="integration.type == 'API' && integration.subtype == 'OAUTH'">
                                <v-subheader style="font-size: 16px">
                                    <v-icon color="secondary" class="mr-2">lock</v-icon> Credentials <v-spacer></v-spacer>
                                </v-subheader>
                                <v-divider></v-divider>
                                <v-list dense>
                                    <v-list-item v-for="(auth) in integration.integration.integrationAuths" :key="auth.id" @click="getOAuthURL(integration.code)">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 12px;">
                                                Name: {{ auth.tenantName }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px">
                                                Tenant ID: {{ auth.tenantId }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="getOAuthURL(integration.code)" v-if="integration.integration.integrationAuths.length == 0">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Connect to {{ integration.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon>launch</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                            <v-card-text v-else-if="integration.type == 'FTP'">
                                <v-subheader style="font-size: 16px">
                                    <v-icon color="secondary" class="mr-2">lock</v-icon>Credentials <v-spacer></v-spacer>
                                    <v-btn color="primary" icon @click="addCredential(integration.type)">
                                        <v-icon>add_circle_outline</v-icon>
                                    </v-btn>
                                </v-subheader>
                                <v-list dense>
                                    <v-list-item v-for="(auth, index) in integration.integration.integrationAuths" :key="auth.id" @click="manageCredential(auth, integration.type, index)">
                                        <v-list-item-content>
                                            <v-list-item-title style="font-size: 12px">
                                                <v-icon color="grey" small class="mr-2">person</v-icon> {{ auth.username }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px;">
                                                <v-icon color="grey" small class="mr-2">dns</v-icon> {{ auth.host }} {{ auth.path }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="font-size: 12px;">
                                                <v-icon color="grey" small class="mr-2">lock</v-icon>
                                                <span v-if="!auth.unlock" class="mb-0 pb-0">**********</span>
                                                <span v-else>{{ auth.password }}</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="integration.integration.integrationAuths.length ==0">
                                        <v-list-item-content class="text-center">
                                            <span style="color: grey">No credentials</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                        <div v-if="integration.name === 'FruitSA'">
                            <b><p>By securely providing your credentials, you authorize us to synchronize your CBR/Ecert information.</p></b>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4" class="mx-0 px-0">
                        <v-card-text>
                            <v-subheader style="font-size: 16px">
                            <v-icon color="secondary" class="mr-2">description</v-icon>
                            Custom Fields
                        <v-spacer></v-spacer>
                        <v-btn v-if="integration.integration && integration.integration.integrationAuths && integration.integration.integrationAuths.length>0 && integration.integrationForms.length>0" color="primary" icon @click="addCustomField(integration)">
                            <v-icon>add_circle_outline</v-icon>
                            </v-btn>
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item v-if="!integration.integrationForms || integration.integrationForms.length == 0">
                                <v-list-item-content class="text-center">
                                    <span style="color: grey">No custom fields</span>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-for="formItem in integration.integrationForms" :key="formItem.id">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span v-if="integrationFormMap[formItem.value]">
                                            {{ integrationFormMap[formItem.value] }}
                                        </span>
                                        <span v-else> -
                                            </span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ formItem.name }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        </v-card-text>
</v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
        <v-card>
            <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;">
                <v-icon color="primary" size="30">close</v-icon>
            </v-btn>
            <v-card-title class="d-flex justify-center">
                FRIKO Welcome to Integration!
            </v-card-title>
            <v-card-text>
                <span>
                    <p>FRIKO</p>
                    <p>FRIKO
                        <br>• Map out routes for transporters
                        <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
                        <br>• Calculate accurate waiting times at loading points
                        <br>• Calculate distances for billing purposes
                        <br>• Pin-point locations for the purpose of reporting and analysis
                        <br>• Map out farms that aren't found on Google Maps
                    </p>
                    If you would like learn how to load POI's into the system, click the button below to start the
                    tutorial.
                </span>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogStart" max-width="31vw">
        <v-card>
            <v-btn @click="dialogStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;">
                <v-icon color="primary" size="30">close</v-icon>
            </v-btn>
            <v-card-title class="d-flex justify-center">
                FRIKO Welcome to Edit/Add Integrations!
            </v-card-title>
            <v-card-text>
                <span>
                    <p>FRIKO</p>
                    <p>FRIKO
                        <br>• Map out routes for transporters
                        <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
                        <br>• Calculate accurate waiting times at loading points
                        <br>• Calculate distances for billing purposes
                        <br>• Pin-point locations for the purpose of reporting and analysis
                        <br>• Map out farms that aren't found on Google Maps
                    </p>
                    If you would like learn how to load POI's into the system, click the button below to start the
                    tutorial.
                </span>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="startDialogGuide" color="edit" class="mb-3">Start!</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="authModal" width="450px" persistent :fullscreen="$vuetify.breakpoint.mobile">
        <v-card v-if="authItem && authItem.type">
            <v-toolbar flat color="transparent">
                <v-toolbar-title>
                    Manage Credentials
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="secondary" @click="saveCredential()" :loading="savingCredential">
                    <v-icon>save</v-icon>
                </v-btn>
                <v-btn icon color="red" v-if="authItem.id" @click="removeCredential()" :loading="deletingCredential">
                    <v-icon>delete</v-icon>
                </v-btn>
                <v-btn text @click="authModal=false, authItem={}">X</v-btn>
            </v-toolbar>
            <v-card-text>
                <div v-if="authItem.type == 'Login'">
                    <v-text-field label="Username" v-model="authItem.username" outlined dense clearable></v-text-field>
                    <v-text-field label="Password" v-model="authItem.password" outlined dense clearable></v-text-field>
                </div>
                <div v-else-if="authItem.type == 'FTP'">
                    <v-text-field label="Username" v-model="authItem.username" outlined dense clearable></v-text-field>
                    <v-text-field label="Password" v-model="authItem.password" outlined dense clearable></v-text-field>
                    <v-text-field label="Host" v-model="authItem.host" outlined dense clearable></v-text-field>
                    <v-text-field label="Path" v-model="authItem.path" outlined dense clearable></v-text-field>
                </div>
                <div v-else-if="authItem.type == 'API' && authItem.subType == 'Client_Credentials'">
                    <v-text-field label="Username" v-model="authItem.username" outlined dense clearable></v-text-field>
                    <v-text-field label="Password" v-model="authItem.password" outlined dense clearable></v-text-field>
                </div>
                <div v-else-if="authItem.type == 'API' && authItem.subType == 'API_Credentials'">
                    <v-text-field label="Client ID" v-model="authItem.username" outlined dense clearable></v-text-field>
                    <v-text-field label="Secret Key" v-model="authItem.password" outlined dense clearable></v-text-field>
                    <v-text-field label="Access Token" v-model="authItem.token" outlined dense clearable></v-text-field>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="formModal" width="400px">
        <v-card>
            <v-toolbar flat dense color="transparent">
                <v-toolbar-title>
                    Custom Fields
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="primary" @click="saveFields" :loading="savingFields">
                    <v-icon>save</v-icon>
                </v-btn>
                <v-btn text @click="formModal = false">
                    X
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-text-field v-for="formItem in form" :key="formItem.id" dense outlined
                :label="formItem.name" v-model="integrationFormResult[formItem.value]" clearable></v-text-field>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import integrationSteps from "./Steps/IntegrationSteps.js";
import dialogSteps from "./Steps/integrationDialogSteps.js"
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";

export default {
    props: ['organisation'],

    data: () => ({
        authItem: {},
        authModal: false,
        availableIntegrations: ["Inttra", "FTP", "Sensor", "DHL", "COO", "sFTP", "API"],
        countryIntegrations: [],
        deletingCredential: false,
        dialog: false,
        updateBadge: 0,
        sortBy: "name",
        sortDesc: false,
        formModal: false,
        form: [],
        headers: [{
                text: "Action",
                value: "action",
                align: "center",
            },
            {
                text: "Alias",
                value: "name",
                align: "center",
            },
            {
                text: "Type",
                value: "type",
                align: "center",
            },
            // {
            //     text: 'Host/IP',
            //     value: 'host_ip',
            //     align: 'center',
            // },updateIntegration
            // {
            //     text:"Path",
            //     value:"path",
            //     align:"center"
            // },
            // {
            //     text:"Keep file",
            //     value:"keep_file",
            //     align:"center"
            // }
        ],
        integrationModal: false,
        integrationFormMap: {},
        integrationFormResult: {},
        loadingCountryIntegrations: false,
        params: {
            limit: 18,
            offset: 0,
            search: null,
            filter: {
                isActive: [true]
            },
        },
        driver: null,
        saving: false,
        savingCredential: false,
        modalType: "",
        loading: false,
        searchTimer: undefined,
        search: null,
        keepFileOnServer: false,
        selectedAddAnotherUser: false,
        integration: {},
        integrationKey: 1000,
        integrations: {
            total: 0,
            data: [],
        },
        infoStart: false,
        dialogStart: false,
        savingFields: false,
    }),
    watch: {
        // integration.type(){
        //     this.username='',
        //     this.password=''
        // },
        'organisation.id': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.load()
                }
            }
        },
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit;
            await this.getIntegrations();
        },
        "params.limit": {
            immediate: true,
            async handler() {
                this.page = 1;
                await this.getIntegrations();
            },
        },
        "params.search": {
            async handler() {
                this.page = 1;
                await this.getIntegrations();
            },
        },
    },
    mounted() {
        // this.getIntegrations();
        this.driver = new Driver({
            animate: false,
        });
    },
    computed: {
        filterCountryIntegrations() {
            let result = this.countryIntegrations
            if (this.search) {
                result = result.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()) ||
                    (item.organisation && item.organisation.name.toLowerCase().includes(this.search.toLowerCase()))

                )
            }
            return result
        }
    },
    // created() {
    //   this.load();
    // },
    methods: {
        addCredential(type, subtype = null) {
            this.authItem = {
                integrationId: this.integration.integration.id,
            }
            this.authItem.type = type
            this.authItem.subType = subtype
            this.authModal = true
        },
        addCustomField(integration) {
            this.form = integration.integrationForms
            this.integrationFormResult = {}
            for (let field of this.form) {
                let find = integration.integration.integrationForms ? integration.integration.integrationForms.find(item => item.value == field.value):null
                this.integrationFormResult[field.value] = find ? find.name : ""
            }
           this.formModal = true
        },
        load() {
            this.getIntegrations();
            if (this.organisation && this.organisation.countryRegion) {
                this.getCountryIntegrations(this.organisation.countryRegion.id)
            }
        },
        manageCredential(auth, type, index, subtype = null) {
            this.authItem = auth
            this.authItem.index = index
            this.authItem.type = type
            this.authItem.subType = subtype
            this.authModal = true
        },
        async getOAuthURL(code) {
            let url = await this.$API.getOAuthURL(code)
            window.open(url, "_self")
        },
        async getIntegrations() {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }
            this.loading = true;
            this.searchTimer = setTimeout(async () => {
                this.integrations = await this.$API.getIntegrations({
                    params: this.params,
                });
                this.loading = false;
            }, 500);
        },
        async getCountryIntegrations(id) {
            this.loadingCountryIntegrations = true
            this.countryIntegrations = await this.$API.getAvailableIntegrations(id)
            this.loadingCountryIntegrations = false
        },

        async addIntegration() {
            this.modalType = "Add";
            this.dialog = true;
        },
        async saveIntegration() {
            this.loading = true;
            if (this.integration && this.integration.id) {
                this.integration = await this.$API.updateIntegration(this.integration);
            } else {
                this.integration = await this.$API.createIntegration(this.integration);
            }
            this.dialog = false;
            this.resetIntegration();
            this.getIntegrations();
            this.loading = false;
        },
        async onNameSelected() {
            this.item.name = this.integration.name;
        },

        addAnotherUser() {
            this.integration.users.push({
                id: Math.random(),
                username: this.integration.username,
                password: this.integration.password,
            });
        },
        deleteUser(user) {
            this.integration.users.splice(this.integration.users.indexOf(user), 1);
        },
        async deleteIntegration(item) {
            this.$confirm("Are you sure you want to delete this Regime?", "Confirm", {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                })
                .then(async () => {
                    try {
                        await this.$API.deleteAuthIntegration(item.id);
                        await this.$API.deleteIntegration(item.id);
                        this.getIntegrations();
                        this.$message({
                            type: "success",
                            message: "Successfully deleted!",
                        });
                    } catch (err) {
                        this.$message({
                            type: "error",
                            message: "Error deleting item: " + err.message,
                        });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Delete canceled",
                    });
                });
        },

        async editIntegrations(item) {
            this.integration = item
            this.modalType = "Edit";
            this.saving = true;
            this.dialog = true;
            this.saving = false;
        },
        activeFilters() {
            let keys = Object.keys(this.params.filter);
            let count = 0;
            for (let i = 0; i < keys.length; i++) {
                if (this.params.filter[keys[i]] && keys[i] != "isActive") {
                    count++;
                }
            }
            return count;
        },
        closeDialog() {
            this.dialog = false;
            this.resetIntegration();
        },
        resetIntegration() {
            this.integration = {
                name: null,
                type: null,
                host: null,
                path: null,
                keep_file: null,
                username: null,
                password: null,
                client_id: null,
                secret_key: null,
                users: [],
            };
        },
        manageIntegration(item) {
            this.integrationFormMap = {}
            this.integration = item
            if (item.integrations && item.integrations.length > 0) {
                this.integration.integration = item.integrations[0]
                if(this.integration.integration.integrationForms){
                    for(let field of this.integration.integration.integrationForms){
                    this.integrationFormMap[field.value] = field.name
                }
                }
            } else {
                // this.integration.integrations[0] = {
                //   type: this.integration.type,
                //   name: this.integration.name,
                //   integrationAuths: []
                // }
                this.integration.integration = {
                    type: this.integration.type,
                    name: this.integration.name,
                    integrationAuths: []
                }
            }
            this.integrationModal = true
        },
        infoStartGuide() {
            if (this.infoStart) {
                this.infoStart = false;
            } else {
                this.infoStart = true;
            }
        },
        async removeCredential() {
            this.deletingCredential = true
            if (this.authItem.id) {
                await this.$API.updateAuthIntegration({
                    id: this.authItem.id,
                    isActive: false,
                    isDeleted: true
                })
            }
            this.integration.integration.integrationAuths.splice(this.authItem.index, 1)
            this.authModal = false
            this.authItem = {}
            this.deletingCredential = false
        },
        async saveCredential() {
            this.savingCredential = true
            if (!this.integration.integration.id) {
                this.integration.integration.countryIntegrationId = this.integration.id
                this.integration.integration = await this.$API.createIntegration(this.integration.integration)
                this.integration.integrations = []
                this.integration.integrations[0] = this.integration.integration
                this.integration.integration.integrationAuths = []
            }
            if (this.authItem.id) {
                let result = await this.$API.updateAuthIntegration(this.authItem)
            } else {
                this.authItem.integrationId = this.integration.integration.id
                let result = await this.$API.createAuthIntegration(this.authItem)
                this.integration.integration.integrationAuths.push(result)
            }
            this.integration.integrations[0] = this.integration.integration
            this.authModal = false
            this.authItem = {}
            this.savingCredential = false
        },
        startGuide(e) {
            this.infoStart = false
            e.stopPropagation();
            this.driver.defineSteps(integrationSteps)
            this.driver.start()
        },
        dialogStartGuide() {
            if (this.dialogStart) {
                this.dialogStart = false;
            } else {
                this.dialogStart = true;
            }
        },
        startDialogGuide(e) {
            this.dialogStart = false
            e.stopPropagation();
            this.driver.defineSteps(dialogSteps)
            this.driver.start()
        },
        async saveFields() {
            if(this.integration.integration){
                this.integrationFormMap = this.integrationFormResult
                this.savingFields = true
                let form = []
                for(let key in this.integrationFormResult){
                    form.push({
                        value: key,
                        name: this.integrationFormResult[key]
                    })
                }
            let result = await this.$API.updateIntegrationForms(this.integration.integration.id, form)
            this.integration.integration.integrationForms = result
            this.savingFields = false
            }
            this.formModal = false           
        },
    },

};
</script>

<style scoped></style>
