const steps = [
  {
    element: "#add-contract-button",
    popover: {
      title: "Add Carrier Contract",
      description:
        "Allows you to create a new Carrier Contract for your organisation",
      position: "bottom",
    },
  },
  {
    element: "#search-contract",
    popover: {
      title: "Search for contract",
      description:
        "Allows you to search for a specific contract(s) for your organisation",
      position: "bottom",
    },
  },
  {
    element: "#edit-contract-button",
    popover: {
      title: "Edit Carrier Contract",
      description:
        "Allows you to edit a Carrier Contract for your organisation",
      position: "bottom",
    },
  },
  {
    element: "#remove-contract-button",
    popover: {
      title: "Remove Carrier Contract",
      description:
        "Allows you to remove Carrier Contract for your organisation",
      position: "bottom",
    },
  },
];

export default steps;
