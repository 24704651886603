const steps = [
  {
    element: "#category-name",
    popover: {
      title: "Category - Name",
      description:
        "This is where you provide the unique name of this manual category",
      position: "left",
    },
  },
  {
    element: "#category-description",
    popover: {
      title: "Category - Description",
      description:
        "This is where you provide the description of this manual category",
      position: "left",
    },
  },
  {
    element: "#category-sub",
    popover: {
      title: "Subcategories",
      description:
        "This is where you can see the list of subcategories and add new ones",
      position: "right",
    },
  },
  {
    element: "#category-save",
    popover: {
      title: "Save Manual Category",
      description: "This is where you save the manualy category's information",
      position: "top",
    },
  },
];

export default steps;
