<template>
    <div style="background: var(--v-component-base) !important; height: 100%; width: 100%">
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="8">
                        <v-row justify="end" class="mb-2">
                            <v-col cols="12" sm="12" md="8" lg="6">
                                <el-input id="search-user" suffix-icon="el-icon-search" v-model="userSearch" clearable
                                    placeholder="Search">
                                    <template slot="prepend">
                                        <el-button id="add-user-button"
                                            @click="userDialog = true, contact = {authProvider: 'System'}, modalType = 'Add'"><i
                                                class="el-icon-plus"></i>
                                        </el-button>
                                    </template>
                                    <template slot="append">
                                        <el-button @click="infoStartGuide">
                                            <v-icon>help</v-icon>
                                        </el-button>
                                    </template>
                                </el-input>
                            </v-col>
                        </v-row>

                        <v-data-table height="70vh" fixed-header id="user-table" :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc" dense :items="filterOrgUsers" :loading="loading" :headers="headers"
                            @dblclick:row="editUserDialog" style="cursor: pointer;" disable-pagination hide-default-footer>
                            <template v-slot:[`item.shared`]="{ item, index }">
                                <v-icon @click="updateShareStatus(item)" :id="'user-shared-column_' + index"
                                    v-if="item.shared" color="green">check_circle_outline</v-icon>
                                <v-icon @click="updateShareStatus(item)" :id="'user-shared-column_' + index" v-else
                                    color="red">cancel
                                </v-icon>
                            </template>
                            <template v-slot:[`item.roles`]="{ item, index }">
                                <div v-if="!item.addRole" :key="tabRefresh" :id="'user-roles-column_' + index"
                                    style="width:100; padding: 5px">
                                    <v-chip v-for="(tag) in item.user.userRoles" :key="tag.id" class="mr-1 mt-1"
                                        color="blue" outlined small label>
                                        {{ tag.role.name }}
                                    </v-chip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-card flat>
                            <v-card-text>
                                <Roles />
                                <Teams />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>


        <v-dialog v-model="userDialog" max-width="900px" :overlay-opacity="0.9" persistent>
            <v-card :loading="loading" style="max-height: 55vh; border-radius: 20px">
                <v-container fill-height class="pa-0 ma-0">
                    <v-row no-gutters style="height: 100%">
                        <v-col cols="12" sm="5" md="5" lg="5">
                            <v-card
                                style="height: 100%; width:100%; background-color: var(--v-greyRaised-base) !important; border-radius: 20px 0 0 20px">
                                <v-row no-gutters>
                                    <v-col cols="12" md="12" sm="12" lg="12" class=" pt-3 pb-3 d-flex justify-center">
                                        <v-avatar class="ma-2" size="168">
                                            <v-img v-if="contact.avatar" :src="contact.avatar" />
                                            <v-icon v-else size="168">person</v-icon>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" md="12" sm="12" lg="12"
                                        class=" pt-3 pb-3 d-flex align-center flex-column"
                                        v-if="!editingProfile && modalType != 'Add'">
                                        <span class="headline">{{ contact.firstname }} {{ contact.surname }}</span>
                                        <!-- <span class="caption pt-2 pb-5 font-weight-medium">{{ contact.position }}</span> -->
                                        <span class="caption pt-5 text--secondary">{{ contact.emailAddress }}</span>
                                        <span class="caption text--secondary">{{ contact.mobileNumber ? contact.mobileNumber
                                            : '000-000-0000' }}</span>
                                    </v-col>
                                    <v-col cols="12" md="12" sm="12" lg="12"
                                        class=" pt-0 pb-0 d-flex align-center flex-column"
                                        v-if="editingProfile || modalType == 'Add'">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6" md="6" lg="6" class="px-2">
                                                <v-text-field v-model="contact.firstname" outlined dense
                                                    label="First Name" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6" class="px-2">
                                                <v-text-field v-model="contact.surname" outlined dense label="Last Name" />
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="6" md="6" lg="6" class="px-2">
                                                <v-text-field v-model="contact.position" outlined dense label="Position" />

                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6" class="px-2">
                                                <v-text-field v-model="contact.mobileNumber" outlined dense
                                                    label="Mobile Number" />
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12" class="px-2">
                                                <v-text-field v-model="contact.emailAddress" outlined dense hide-details
                                                    label="Email Address" />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-btn style="position: absolute; top: 0; right: 0;" icon
                                    v-if="$store.state.user.id == contact.id" :loading="loadingEdit"
                                    @click="modalType = 'Edit'">
                                    <v-icon>{{ modalType == 'Edit' ? 'save' : 'edit' }}</v-icon>
                                </v-btn>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="7" md="7" lg="7">
                            <v-card
                                style="border-radius: 0 20px 20px 0; background-color: var(--v-greyBase-base) !important; contain:content">
                                <v-toolbar dense
                                    style="border-radius: 0 20px 0 0;background-color: var(--v-greyBase-base) !important"
                                    flat>
                                    <v-spacer></v-spacer>
                                    <v-tooltip top v-if="contact.id">
                                        <template v-slot:activator="{ on }">
                                            <v-btn @click="sendUserInvite(contact)" :loading="sendingUserInvite" icon
                                                class="mx-1" v-on="on" color="white"><v-icon>email</v-icon></v-btn>
                                        </template>
                                        <span>Send User Invite</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon color="white" v-on="on" elevation="0"
                                                :disabled="!validateEmail(contact.emailAddress)"
                                                @click="modalType == 'Edit' ? saveUser() : addUser()">
                                                <v-icon>save</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Save User</span>
                                    </v-tooltip>
                                    <v-tooltip top  v-if="contact.id">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" icon color="red" elevation="0"
                                                @click="removeUser()">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Remove user from organisation</span>
                                    </v-tooltip>

                                    <v-btn plain depressed elevation="0" @click="clearDialog()">
                                        <v-icon>close</v-icon>
                                    </v-btn>

                                </v-toolbar>
                                <v-card-text>
                                    <v-select small-chips dense outlined v-model="contact.organisationAddressId"
                                        label="User Office" :items="addresses" item-value="id" item-text="alias"></v-select>
                                    <v-select dense multiple chips outlined closable :items="orgTeams" label="User Teams"
                                        item-value="id" item-text="name" v-model="selectedTeams" append-icon="groups">
                                        <template #selection="{ item }">
                                            <v-chip small :color="item.colour">{{ item.name }}</v-chip>
                                        </template>
                                    </v-select>
                                    <v-select small-chips dense multiple chips outlined closable :items="roles"
                                        label="User Roles" item-value="id" item-text="name" v-model="selectedRoles"
                                        append-icon="manage_accounts">
                                    </v-select>
                                    <v-text-field v-model="contact.position" outlined dense label="Position" />
                                    <v-select hide-details dense outlined v-model="contact.authProvider"
                                        label="Authentication Provider*" :items="['Google', 'System']"></v-select>
                                    <v-checkbox hide-details v-model="contact.shared" label="Shared Contact"></v-checkbox>
                                    <small>Someone who represents your company when clients search for your
                                        business in our system.</small>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <v-dialog v-model="infoStart" max-width="31vw">
            <v-card>
                <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
                        color="primary" size="30">close</v-icon></v-btn>
                <v-card-title class="d-flex justify-center">
                    FRIKO Welcome to Users!
                </v-card-title>
                <v-card-text>
                    <span>
                        <p>FRIKO</p>
                        <p>FRIKO
                            <br>• Map out routes for transporters
                            <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
                            <br>• Calculate accurate waiting times at loading points
                            <br>• Calculate distances for billing purposes
                            <br>• Pin-point locations for the purpose of reporting and analysis
                            <br>• Map out farms that aren't found on Google Maps
                        </p>
                        If you would like learn how to load POI's into the system, click the button below to start the
                        tutorial.
                    </span>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import Roles from './Roles.vue'
// import steps from './Steps/userSteps'
import Teams from './Team.vue'
export default {
    components: {
        Roles,
        Teams
    },
    data: () => ({
        steps: null,
        addresses: [],
        contact: {},
        driver: null,
        teamHeaders: [
            {
                text: 'Action',
                align: 'center',
                sortable: false,
                value: 'action'
            },
            {
                text: 'Name',
                align: 'center',
                sortable: false,
                value: 'name'
            }
        ],
        headers: [{
            text: 'Name',
            value: 'user.firstname',
            align: 'center',
        },
        {
            text: 'Surname',
            value: 'user.surname',
            align: 'center',
        },
        {
            text: 'Email',
            value: "user.emailAddress",
            align: 'center',
        },
        {
            text: 'Position',
            value: "position",
            align: 'center',
        },
        {
            text: 'Office',
            value: "organisationAddress.alias",
            align: 'center',
        },
        {
            text: 'Shared',
            value: "shared",
            align: 'center',
        },
        {
            text: 'Auth Provider',
            sortable: false,
            value: "user.authProvider",
            align: 'center',
        },

        {
            text: 'Roles',
            value: 'roles',
            sortable: false,
            align: 'start',
            width: '250px'
        }
        ],
        loading: false,
        loadingEdit: false,
        editingProfile: false,
        orgUsers: [],
        orgTeams: [],
        contactTeams: [],
        selectedTeams: [],
        roles: [],
        contactRoles: [],
        selectedRoles: [],
        tabRefresh: 0,
        tempUsers: [],
        sortBy: 'user.firstname',
        sortDesc: false,
        modalType: '',
        sendingUserInvite: false,
        users: {
            count: 0,
            data: []
        },
        userDialog: false,
        userPage: 1,
        userParams: {
            limit: 100,
            offset: 0,
            search: null
        },
        userSearch: '',
        infoStart: false,
    }),
    computed: {
        filterOrgUsers() {
            let result = this.orgUsers
            if (this.userSearch) {
                result = result.filter(x =>
                    (x.user.firstname.toLowerCase()).includes(this.userSearch.toLowerCase()) ||
                    (x.user.surname.toLowerCase()).includes(this.userSearch.toLowerCase()) ||
                    (x.user.emailAddress.toLowerCase()).includes(this.userSearch.toLowerCase()) ||
                    (x.user.authProvider.toLowerCase()).includes(this.userSearch.toLowerCase())
                )
            }
            return result
        },
        currentRouteName() {
            return this.$route.name;
        }
    },
    watch: {
        sortBy(value) {
            console.log(value)
        }
    },
    async created() {
        await this.getTeams()
        await this.getSteps()
    },
    async mounted() {
        this.loadOrganisation()
        this.driver = new Driver({
            animate: false
        })
    },
    methods: {
        async getSteps() {
        let guide = await this.$API.getGuideSteps({
            guideId: 'Management/Users/Steps',
        });
        this.steps = guide.steps
        },
        async addUser() {
            try {
                this.loading = true;
                console.log('Contact', this.contact)
                let contact = await this.$API.createUser(this.contact)
                let orgUser = await this.$API.createOrganisationUser({
                    userId: contact.id,
                    shared: this.contact.shared,
                    position: this.contact.position,
                    organisationAddressId: this.contact.organisationAddressId

                })
                this.contact.orgUserId = orgUser.id
                this.contact.id = contact.id
                await this.saveTeamsAndRoles()
                this.loading = false
                this.clearDialog()
                this.$message({
                    type: 'success',
                    message: 'User created successfully!'
                })
            } catch (e) {
                this.$message({
                    type: 'warning',
                    message: 'Creating user failed.'
                })
            }
        },
        async getTeams() {
            let teams = await this.$API.getTeams()
            this.orgTeams = teams
        },
        async saveUser() {
            try {
                this.loading = true;
                await this.$API.updateUser({
                    id: this.contact.id,
                    firstname: this.contact.firstname,
                    surname: this.contact.surname,
                    emailAddress: this.contact.emailAddress,
                    authProvider: this.contact.authProvider,
                    mobileNumber: this.contact.mobileNumber,
                    position: this.contact.position,
                })
                await this.$API.updateOrganisationUser({
                    id: this.contact.orgUserId,
                    shared: this.contact.shared,
                    position: this.contact.position,
                    organisationAddressId: this.contact.organisationAddressId
                })
                await this.saveTeamsAndRoles()
                this.loading = false
                this.clearDialog()
                this.$message({
                    type: 'success',
                    message: 'User saved successfully!'
                })
            } catch (e) {
                this.$message({
                    type: 'warning',
                    message: 'User update failed.'
                })
            }
        },
        async saveTeamsAndRoles() {
            let selectedRoles = this.roles.filter(role => this.selectedRoles.includes(role.id))
            await this.addUserRoles(this.getDifference(selectedRoles, this.contactRoles))
            await this.removeUserRoles(this.getDifference(this.contactRoles, selectedRoles))
            let selectedTeams = this.orgTeams.filter(team => this.selectedTeams.includes(team.id))
            await this.addUserTeams(this.getDifference(selectedTeams, this.contactTeams))
            await this.removeUserTeams(this.getDifference(this.contactTeams, selectedTeams))
        },
        async addUserRoles(roles) {
            for (let i = 0; i < roles.length; i++) {
                await this.$API.createUserRole({
                    roleId: roles[i].id,
                    userId: this.contact.id
                })
            }
        },
        async removeUserRoles(roles) {
            for (let i = 0; i < roles.length; i++) {
                let userRoleId = this.contact.roles.filter(x => x.roleId == roles[i].id)
                await this.$API.updateUserRole({
                    id: userRoleId[0].id,
                    isActive: false,
                    isDeleted: true
                })
            }
        },
        async addUserTeams(teams) {
            for (let i = 0; i < teams.length; i++) {
                let userTeam = await this.$API.createTeamUser({
                    teamId: teams[i].id,
                    organisationUserId: this.contact.orgUserId
                })
                this.orgTeams[this.orgTeams.indexOf(teams[i])].userTeams.push(userTeam)
            }
        },
        async removeUserTeams(teams) {
            for (let i = 0; i < teams.length; i++) {
                let userTeam = teams[i].userTeams.filter(x => x.organisationUserId == this.contact.orgUserId)
                await this.$API.updateTeamUser({
                    id: userTeam[0].id,
                    isActive: false,
                    isDeleted: true
                })
                this.orgTeams[this.orgTeams.indexOf(teams[i])].userTeams
                    .splice(this.orgTeams[this.orgTeams.indexOf(teams[i])].userTeams.indexOf(userTeam[0]), 1)
            }
        },
        getDifference(array1, array2) {
            return array1.filter(object1 => {
                return !array2.some(object2 => {
                    return object1.id === object2.id
                })
            })
        },
        clearDialog() {
            this.userDialog = false
            this.contact = {}
            this.selectedRoles = []
            this.contactRoles = []
            this.contactTeams = []
            this.selectedTeams = []
            this.getOrganisationUsers()
        },
        addUserToRole(user) {
            let index = this.orgUsers.indexOf(this.orgUsers.find(x => x.id == user.id))
            this.orgUsers[index].addRole = true
            this.orgUsers[index].userRoles = this.orgUsers[index].user.userRoles.map(x => x.roleId)
            this.tabRefresh++
            this.$nextTick(() => {
                this.$refs['addToRoleInput_' + user.id].$refs.input.focus();
            });
        },
        async getRoles() {
            this.roles = await this.$API.getOrganisationRole()
        },
        async processUserRoleChanges(data) {
            let index = this.orgUsers.indexOf(this.orgUsers.find(x => x.id == data.id))
            this.orgUsers[index].addRole = false
            let add = data.userRoles.filter(x => !data.user.userRoles.map(y => y.roleId).includes(x))
            let remove = data.user.userRoles.filter(x => !data.userRoles.includes(x.roleId))
            let obj = [...add.map(x => ({
                userId: data.userId,
                roleId: x
            })), ...remove.map(x => ({
                id: x.id,
                isActive: false,
                isDeleted: true
            }))]
            if (obj.length > 0) {
                await this.$API.createUserRole({
                    batch: obj
                })
                this.getOrganisationUsers()
            }
            this.tabRefresh++
        },
        filterUserChange(data) {
            this.userParams.filter = data
            // this.getUsers()
        },
        async loadOrganisation() {
            this.getOrganisationUsers()
            this.getRoles()
            this.getOrganisationAddresses()
        },
        async getOrganisationUsers() {
            this.loading = true
            this.orgUsers = await this.$API.getOrganisationUsers()
            this.loading = false
        },
        async getOrganisationAddresses() {
            this.addresses = await this.$API.getOrganisationAddress()
            console.log('Addresses', this.addresses)
        },
        async editUserDialog(click, row) {
            this.modalType = 'Edit'
            this.contact = {
                id: row.item.user.id,
                orgUserId: row.item.id,
                firstname: row.item.user.firstname,
                surname: row.item.user.surname,
                emailAddress: row.item.user.emailAddress,
                position: row.item.position,
                shared: row.item.shared,
                authProvider: row.item.user.authProvider,
                mobileNumber: row.item.user.mobileNumber,
                avatar: row.item.user.avatar,
                roles: row.item.user.userRoles,
                organisationAddressId: row.item.organisationAddressId
            }
            this.selectedRoles = row.item.user.userRoles.map(x => x.roleId)
            this.contactRoles = this.roles.filter(x => this.selectedRoles.includes(x.id))
            this.contactTeams = this.orgTeams.filter(x => x.userTeams.some(y => y.organisationUserId == this.contact.orgUserId))
            this.selectedTeams = this.contactTeams.map(x => x.id)
            this.userDialog = true
        },
        async removeUser() {
            this.$confirm('Are you sure you want to remove this user?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateOrganisationUser({
                    id: this.contact.orgUserId,
                    isActive: false,
                    isDeleted: true
                })
                this.clearDialog()
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });

        },
        async sendUserInvite(user) {
            this.sendingUserInvite = true
            await this.$API.createEmailNotification('UserOrganisationInvite', {
                organisationId: this.$store.state.currentOrg.id,
                userId: user.id
            })
            this.$message.success('Invite sent successfully!')
            this.sendingUserInvite = false
        },
        async updateShareStatus(user) {
            try {
                user.shared = !user.shared
                await this.$API.updateOrganisationUser({
                    id: user.id,
                    shared: user.shared
                })
                this.$message({
                    type: 'success',
                    message: 'Successfully updated!'
                });
            } catch (e) {
                this.$message({
                    type: 'info',
                    message: 'Update failed'
                });
            }
        },
        validateEmail(email) {
            let result = false
            if (email) {
                result = email.match(
                    //eslint-disable-next-line
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            }
            return result
        },
        infoStartGuide() {
            if (this.infoStart) {
                this.infoStart = false;
            } else {
                this.infoStart = true;
            }
        },
        startGuide(e) {
            this.infoStart = false
            e.stopPropagation();
            this.driver.defineSteps(this.steps)
            this.driver.start()
        },
    }
}
</script>

<style lang="scss">
.add-user-button {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background: rgba(0, 0, 0, .025)
    }
}
</style>
