const steps = [
  {
    element: "#add-sf-button",
    popover: {
      title: "Add Service Failure",
      description: "Allows you to create a service failure",
      position: "bottom",
    },
  },
  {
    element: "#search-sf",
    popover: {
      title: "Search for Service Failures",
      description: "Allows you to search for a specific server failure(s)",
      position: "bottom",
    },
  },
  {
    element: "#edit-sf-button",
    popover: {
      title: "Edit Service Failure",
      description: "Allows you to edit a service failure",
      position: "bottom",
    },
  },
  {
    element: "#remove-sf-button",
    popover: {
      title: "Remove Service Failure",
      description: "Allows you to remove a service failure",
      position: "bottom",
    },
  },
];

export default steps;
