const steps = [
  {
    element: "#shipping-line",
    popover: {
      title: "Shipping Line",
      description:
        "This is where you provide the shipping line for this contract",
      position: "left",
    },
  },
  {
    element: "#shipping-contract",
    popover: {
      title: "Contract Number",
      description:
        "This is where you provide the contract number of this contract",
      position: "right",
    },
  },
  {
    element: "#end-date",
    popover: {
      title: "End Date",
      description:
        "This is where you choose the date which this contract ends on",
      position: "left",
    },
  },
  {
    element: "#target-number",
    popover: {
      title: "Target Number of Containers",
      description:
        "This is where to decide what the target number of containers this contract will have",
      position: "right",
    },
  },
  {
    element: "#target-region",
    popover: {
      title: "Target Regions(s)",
      description: "This is where to decide what are the target region(s)",
      position: "top",
    },
  },
];

export default steps;
